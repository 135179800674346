import { isValidPhoneNumber } from "libphonenumber-js";
import { jwtDecode } from "jwt-decode";
import { TCredentials, TUser } from "../types/context";
import moment from "moment";
import { GenericObjectArrayType, TActions } from "../types/common";
import {
  APPROVED,
  CONST_CREDENTIALS_KEY,
  DATE_FORMAT,
  DEFERRED,
  DELETE,
  EDIT,
  FILE_TYPE_DOC,
  FILE_TYPE_DOCX,
  FILE_TYPE_JPEG,
  FILE_TYPE_JPG,
  FILE_TYPE_PDF,
  FILE_TYPE_PNG,
  PENDING,
  RECOMMENDED_FOR_APPROVAL,
  REJECTED,
  SUBMITTED,
  VIEW,
} from "./constants";
import { BASE_API_URL } from "../config";
import { useAuth } from "../context/AuthContext";
import { renderSingleFile } from "../components/common/FileViewer";
import { axiosInstance } from "../hooks/common/useAxios";
import styled from "styled-components";

export const setCredentials = (value: TCredentials) => {
  try {
    window.localStorage.setItem(CONST_CREDENTIALS_KEY, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

export const removeCredentials = () => {
  try {
    localStorage.clear();
  } catch (e) {
    // saving error
  }
};

export const decodeUser = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);
    const user_id = decoded.user_id;
    return { id: user_id, ...decoded.user };
  } catch (err) {
    // saving error
  }
};

export const getCredentials = () => {
  try {
    const res = window.localStorage.getItem(CONST_CREDENTIALS_KEY);
    return res ? JSON.parse(res) : null;
  } catch (e) {
    // saving error
  }
};

export const generateDocumentAuthor = (user: any) => ({
  author_first_name: user?.first_name,
  author_last_name: user?.last_name,
  author_email: user?.email,
  author_phone_number: user?.phone_number || "0709456322",
  created_by: user?.id,
});

export const getAndPrepareFiles = (
  form: any,
  fileFieldNames: string[],
  field: string = "",
) => {
  const uploadedFiles: any = [];
  fileFieldNames.forEach((item) => {
    if (form[item]) {
      uploadedFiles.push(form[item]);
      delete form[item];
    }
  });
  const prefix = field || "attachments";
  return {
    ...form,
    ...prepareAttachmentsPost(uploadedFiles, prefix),
  };
};

export const prepareAttachmentsPost = (
  attachments: any,
  field?: any,
  object?: any,
  noFile?: any,
) => {
  const prefix = field || "attachments";
  const extras = {};
  const atts = (attachments || []).filter((item: any) => noFile || item?.file);
  const inflated_data: any = [];
  atts.forEach((item: any) => {
    if (item?.file) {
      item?.file.forEach((it: any) => {
        inflated_data.push({ ...item, file: it });
      });
    } else {
      inflated_data.push(item);
    }
  });

  inflated_data
    .map((item: any) => ({ ...item, ...object }))
    .forEach((item: any, idx: any) => {
      Object.keys(item).forEach((key) => {
        extras[`${prefix}[${idx}]${key}`] = item[key];
      });
    });
  return extras;
};

export const prepareObjectFormdataPost = (parentObject, field) => {
  const extras = {};
  const object = parentObject[field];
  if (!object) {
    return parentObject;
  }
  Object.keys(object).forEach((key) => {
    extras[`${field}.${key}`] = object[key];
  });
  parentObject = { ...parentObject, ...extras };
  delete parentObject[field];
  return parentObject;
};
export const prepareObjectArrayFormdataPost = (parentObject, field) => {
  const extras = {};
  const object = parentObject[field];
  if (!object) {
    return parentObject;
  }
  object.forEach((item, idx) => {
    Object.keys(item).forEach((key) => {
      extras[`${field}[${idx}]${key}`] = item[key];
    });
  });
  parentObject = { ...parentObject, ...extras };
  delete parentObject[field];
  return parentObject;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
    ? null
    : "Please enter a valid email address";
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return isValidPhoneNumber(`${phoneNumber}`)
    ? null
    : "Please enter a valid phone number";
};

export const validateConfirmPass = (
  password: string,
  confirmPassword: string,
) => {
  return password === confirmPassword ? null : "Both passwords should match";
};

export const generateErrorString = (error: any) => {
  let errorString = "";
  Object.keys(error || {}).forEach((key) => {
    errorString += ` ${error[key]}`;
  });
  return errorString;
};

export const timeToString = (minutes: number) => {
  let str = "";
  if (minutes <= 60) {
    str = `${minutes}mins`;
  } else if (minutes <= 1440) {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    str = `${hrs}hrs ${mins}mins`;
  } else {
    const days = Math.floor(minutes / 1440);
    const mins = Math.floor(minutes % 1440);
    const hrs = Math.floor(mins / 60);
    str = `${days}days ${hrs}hrs`;
  }

  return str;
};

export const breakColArray = (array: any[]) => {
  const col1Fields: any[] = [];
  const col2Fields: any[] = [];
  array.forEach((item, idx) => {
    if (idx % 2 === 0) {
      col1Fields.push(item);
    } else {
      col2Fields.push(item);
    }
  });
  return { col1Fields, col2Fields };
};

export const returnObject = (show: boolean, object: any) => {
  return show ? object : [];
};

export const beautify = (message?: string) => {
  return (message || "")
    .replaceAll("_", " ")
    .replaceAll("/", "")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const stringifyError = (message: any) => {
  if (!message) return "An unknown error occured";

  if (typeof message === "string") return message;

  let obj;
  if (Array.isArray(message) && message.length) {
    obj = String(message?.[0]);
  }

  return obj ? String(obj) : "An unknown error occured";
};

export const stringifyName = (user: TUser | null) => {
  return user
    ? `${user?.first_name} ${user?.last_name} ${
        user?.other_name ? user?.other_name : ""
      }`
    : "";
};

export const toPastTense = (verb: string) => {
  // List of irregular verbs (cast to object with string keys)
  const irregularVerbs = {
    go: "went",
    eat: "ate",
    drink: "drank",
    leave: "left",
    cancel: "cancelled",
    delete: "deleted",
    resend_invitation: "resent invitation for",
  } as const;

  // Check if the verb is in the list of irregular verbs
  if (verb in irregularVerbs) {
    return irregularVerbs[verb as keyof typeof irregularVerbs];
  }

  return verb + "ed";
};

export const returnArray = (data: any) => {
  return Array.isArray(data) ? data : [];
};

export const stringifyDate = (date: Date) => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Check if the date stored in the variable 'date' is today's date
  if (date.getDate() === today.getDate()) {
    return "Today";
  } else if (date.getDate() === yesterday.getDate()) {
    return "Yesterday";
  } else {
    return moment(date).format("ddd MMM DD, YYYY");
  }
};

export const detailsCardView = (item: any) => {
  let new_item: any = {};
  Object.keys(item).map((key) => {
    if (!key.includes("-name"))
      new_item[key] =
        item[`${key}-name`] != null ? item[`${key}-name`] : item[key];
  });
  return new_item;
};

export const stringifyWeeklyDate = (date: Date) => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Check if the date stored in the variable 'date' is today's date
  if (date.getDate() === today.getDate()) {
    return "Today";
  } else if (date.getDate() === yesterday.getDate()) {
    return "Yesterday";
  } else {
    return moment(date).format("ddd MMM DD, YYYY");
  }
};

export const computerLastWorkingDate = (date: string) => {
  // Get yesterday's date
  const lastDay = moment(date).subtract(1, "day");

  // Check if yesterday was a Sunday
  if (lastDay.day() === 0) {
    // Sunday is represented by 0
    lastDay.day(-2);
  }

  return lastDay;
};

export const computerNextWorkingDate = (date: string) => {
  const today = moment(date);

  // Check if this date is a Friday
  if (today.day() === 5) {
    today.add(3, "days");
  } else {
    today.add(1, "days");
  }

  return today;
};

export const getTitle = (action?: TActions, postfix?: string) => {
  switch (action) {
    case "create":
      return `Create ${postfix || ""}`;
    case "edit":
      return `Update ${postfix || ""}`;
    case "delete":
      return `Delete ${postfix || ""}`;
    case "activate":
      return "Activate User";
    case "ban":
      return "Deactivate User";
    case "resend_invitation":
      return "Resend Invitation";
    default:
      return "";
  }
};

export const getToastTitle = (action?: TActions, postfix?: string) => {
  switch (action) {
    case "create":
      return `created ${postfix || ""}`;
    case "edit":
      return `updated ${postfix || ""}`;
    case "delete":
      return `deleted ${postfix || ""}`;
    default:
      return "";
  }
};

export const sanitizeAction = (action?: TActions) => {
  switch (action) {
    case "create":
      return "create";
    case "edit":
      return "update";
    case "delete":
      return "delete";
    case "resend_invitation":
      return "resend invitation";
    default:
      return "";
  }
};

export const scoreStype = (value: number) => {
  if (value >= 70) {
    return "success";
  } else if (value >= 50 && value < 70) {
    return "ready";
  } else {
    return "danger";
  }
};

export const getQueryParams = (search: string) => {
  return Object.fromEntries(new URLSearchParams(search));
};

export const mondayThisWeek = () => {
  // Get current date
  const today = moment();

  // Calculate Monday of this week
  const mondayOfThisWeek = today.startOf("isoWeek");

  return mondayOfThisWeek.format("YYYY-MM-DD");
};

export const fridayThisWeek = () => {
  // Get current date
  const today = moment();

  // Calculate Friday of this week
  const fridayOfThisWeek = today.endOf("isoWeek").subtract(2, "days");

  return fridayOfThisWeek.format("YYYY-MM-DD");
};

export const goBackOneWeek = (date: string) => {
  const today = moment(date);
  // Calculate Monday of last week
  const lastWeek = today.subtract(1, "week");
  return lastWeek.format("YYYY-MM-DD");
};

export const stringifyAction = (action: TActions, identifier: string) => {
  switch (action) {
    case "delete":
      return `delete this ${identifier}`;
    default:
      return "";
  }
};

export const getActionToolTip = (action: TActions) => {
  switch (action) {
    case "delete":
      return "Delete";
    case "ban":
      return "Deactivate";
    default:
      return "";
  }
};

export const getPhotoSource = (photo: string) => {
  return photo ? `${BASE_API_URL}${photo}` : "";
};

export const beautifyStringWithUnderscore = (str: string) => {
  return str.replace(/_/g, " ");
};

export const greetings = () => {
  const hour = moment().hour();
  if (hour >= 0 && hour < 12) {
    return "Good morning";
  } else if (hour >= 12 && hour < 17) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

export const createColumn = (
  id: any,
  header?: any,
  hasFilter: boolean = false,
) => {
  return {
    id,
    header: header || beautify(id),
    ...returnObject(hasFilter, { filter: {} }),
  };
};

export const formatDate = (value: any, format?: string) => {
  return moment(value).format(format || DATE_FORMAT);
};
export const getAgo = (value: any) => {
  return moment(value).fromNow();
};

export const numberFormat = (value: number) =>
  Number(value || 0).toLocaleString();

export function getRegister(id: string, status?: string) {
  const suffix = status ? `/${status}` : "";
  return `/register${id}${suffix}`;
}

export function getForm(source_id: string, id?: string) {
  if (id) return `${source_id}/update/${id}`;
  return `${source_id}/create`;
}

export function getDetailsView(source_id: string, id: string) {
  return `${source_id}/view/${id}`;
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case PENDING:
    case SUBMITTED:
      return "gold-inverse";
    case RECOMMENDED_FOR_APPROVAL:
      return "blue-inverse";
    case REJECTED:
      return "red-inverse";
    case APPROVED:
    case "current":
      return "green-inverse";
    case DEFERRED:
      return "orange-inverse";
    default:
      return "";
  }
};

export const createSelectItem = (id: string, name?: string) => {
  return { id, name: name || capitalize(beautify(id)) };
};

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const createEditAction = (url: string, id: string) => {
  return {
    label: EDIT,
    to: getForm(url, id),
  };
};

export const createViewAction = (url: string, id: string) => {
  return {
    label: VIEW,
    to: getDetailsView(url, id),
  };
};

export const createDeleteAction = (url: string, identifier: string) => {
  return {
    label: DELETE,
    deleteUrl: url,
    deleteMessage: `Are you sure you want to delete ${identifier}?`,
  };
};

export const createViewEdit = (
  url: string,
  id: string,
  shouldEdit: boolean = true,
) => {
  const views = [createViewAction(url, id)];
  shouldEdit && views.push(createEditAction(url, id));
  return views;
};

export function currentYear() {
  return new Date().getFullYear();
}

export function removeEmpty(array: [], key: string) {
  return array?.filter((val) => {
    return val[key] > 0;
  });
}

export const scaleFormatter = (value: any) => {
  let label = Intl.NumberFormat("en", { notation: "compact" }).format(value);
  return label;
};

export function getValidators(sections: any[]) {
  let validators: any = [];
  sections?.map((item) => {
    if (item.fields) validators = [...validators, ...item.fields];
  });
  return validators;
}

const removeUnusedFields = (form_data: any) => {
  if (form_data == null || typeof form_data !== "object") {
    return form_data;
  }
  const ignoredFields = [
    "id",
    "created_at",
    "updated_at",
    "created_by",
    "updated_by",
    "deleted",
    "declaration",
    "attachments",
  ];
  Object.keys(form_data).forEach((key) => {
    if (ignoredFields.includes(key)) {
      delete form_data[key];
    }
  });
  return form_data;
};
export const objectWithUnsetKeys = (object, keys) => {
  const temp = { ...object };
  keys.forEach((item) => {
    delete temp[item];
  });
  return temp;
};
export const removeUnnecessaryFields = (data: any, keys: string[] = []) => {
  if (Array.isArray(data)) {
    return data.map((it: any) => removeUnusedFields(it));
  }
  const newData = removeUnusedFields(data);
  if (keys) {
    return objectWithUnsetKeys(newData, keys);
  }
  return newData;
};

export const useGetUserPermissions = () => {
  const { user } = useAuth();
  const permissions = user?.role_details?.[0]?.permissions ?? [];
  return permissions?.map((item: any) => item.codename);
};

// check if any of the items exists in an array
export const itemExists = (itemsArray, itemsList) => {
  let available = false;
  itemsList.map((item) => {
    if (itemsArray.includes(item)) {
      available = true;
      return;
    }
  });
  return available;
};

export const isPdf = (file) => {
  return [FILE_TYPE_PDF].includes(file.split(".").pop());
};

export const isMSWord = (file) => {
  return [FILE_TYPE_DOCX, FILE_TYPE_DOC].includes(file.split(".").pop());
};

export const isImage = (file) => {
  return [FILE_TYPE_PNG, FILE_TYPE_JPEG, FILE_TYPE_JPG].includes(
    file.split(".").pop(),
  );
};

export const NoWrap = styled.div`
  white-space: nowrap;
`;
export const downloadThroughAnchorLink = (downloadUrl, fileName) => {
  return axiosInstance
    .get(downloadUrl, {
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const displayDocuments = (docs) => {
  const st = {};
  Object.keys(docs || {})
    .filter((key) => docs[key])
    .map((key) => docs[key])
    .forEach((item) => {
      item.forEach((doc, idx) => {
        const caption = idx > 0 ? idx + 1 : "";
        st[`${doc.caption}_${caption}`] = renderSingleFile(doc);
      });
    });

  return st;
};

export const getNonNullKeys = (
  obj: Record<string, any>,
  excludeKeys: string[] = [],
) => {
  return Object.keys(obj).filter(
    (key) => obj[key] !== null && !excludeKeys.includes(key),
  );
};

export const showObjectItems = (
  obj: Record<string, any>,
  excludeKeys: string[] = [],
) => {
  let cleanedObj: Record<string, any> = {};

  for (let key in obj) {
    if (
      obj[key] !== null &&
      typeof obj[key] !== "object" &&
      !excludeKeys.includes(key)
    ) {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
};

export const getResultOptions = (
  data: any[] | Record<string, any>,
  itemName: string | undefined,
  key?: string | undefined | null,
  otherItemName?: string | undefined | null,
) => {
  const results = Array.isArray(data) ? data : data?.results || [];
  return results.length
    ? results.map((item: GenericObjectArrayType) => ({
        id: key ? item[key] : item.id,
        name:
          `${(itemName && item[itemName]) || ""} ${
            (otherItemName && item[otherItemName]) || ""
          }`.trim() || item.name,
        data: item,
      }))
    : [];
};
